@font-face {
  font-family: 'icon-font';
  src:
    url('fonts/icon-font.ttf?mk4rq0') format('truetype'),
    url('fonts/icon-font.woff?mk4rq0') format('woff'),
    url('fonts/icon-font.svg?mk4rq0#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-external-link:before {
  content: "\e900";
}
