@charset "utf-8";
@import "icons/icon-font";
@import "bulma"; // bulma v0.9.4
@import "katex/katex.min"; // katex v0.16.9
@import "syntax"; // hugo gen chromastyles --style=monokai > syntax.css

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.footer.is-condensed {
    padding: 1.5rem 1.5rem 3rem
}

.columns.is-reversed {
    flex-direction: row-reverse;
}

.is-separator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &::before {
        content: "|";
    }
}

.footnote-ref, .footnote-backref {
    user-select: none;
}

.is-sticky-top {
    position: sticky;
    top: 1rem;
}

a {
    .icon-external-link {
        display: none;
    }
    &[href^="http://"], &[href^="https://"] {
        .icon-external-link {
            display: inline;
            font-size: 0.75em;
        }
    }
}

.markdown-body {
    h1[id], h2[id], h3[id], h4[id], h5[id], h6[id] {
        a.anchor {
            color: $grey;
            visibility: hidden;
            margin-left: 0.25rem;
            user-select: none;
            &:hover {
                color: $grey-dark;
            }
        }
        &:hover {
            a.anchor {
                visibility: visible;
            }
        }
    }

    .highlight {
        margin-bottom: 1rem;
        .ln {
            user-select: none;
        }
    }

    figure.image>img {
        box-shadow: $shadow;
    }

    a {
        word-break: break-all;
    }
}

@media print {
    .is-hidden-print {
        display: none;
    }
    .column {
        &.is-full-print {
            width: 100%;
        }
    }
}
